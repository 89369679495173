import { Image } from '@rsa-digital/evo-shared-components/commonTypes/image';
import ArticleSection from '@rsa-digital/evo-shared-components/components/ArticleSection';
import { ArticleBlock } from '@rsa-digital/evo-shared-components/components/ArticleSection/types';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { InPageNavDesktop } from '@rsa-digital/evo-shared-components/components/InPageNav';
import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { VideoObject } from 'schema-dts';
import Hero, { HeroData } from 'components/Hero';
import { processImageAsset, processMandatoryVideo } from 'helpers/csTypeProcessors';
import { CsAsset, CsVideo } from 'types/contentStack';
import { FullGridInPageNavMobile } from './styles';

export type InPageNavArticleSectionProps = {
  article_section: {
    article_blocks: CsArticleBlock[];
  };
  nav_section: {
    inline_nav_text: string;
  };
};

type ArticleBlockWithVideoJsonLd = ArticleBlock & { jsonLd?: string };

type CsArticleBlock = {
  article_text: {
    text: string;
  } | null;
  image_full_width: {
    image: CsAsset;
  } | null;
  image_half_width: {
    image: CsAsset;
    image_alignment: 'left' | 'right';
    wrapped_text?: string | null;
  } | null;
  video: {
    video: [CsVideo];
  } | null;
};

export const query = graphql`
  fragment InPageNavArticleSectionNews on cs__news_article_page_sections {
    article_section {
      article_blocks {
        article_text {
          text
        }
        image_full_width {
          image {
            ...CsAsset
          }
        }
        image_half_width {
          image {
            ...CsAsset
          }
          image_alignment
          wrapped_text
        }
        video {
          video {
            url
            screen_reader_text
            json_ld
          }
        }
      }
    }
    nav_section {
      inline_nav_text
    }
  }
`;

const processMandatoryImage = (image: CsAsset | null | undefined): Image => {
  const processedImage = processImageAsset(image ?? null);
  if (processedImage) {
    return processedImage;
  }
  /* istanbul ignore next */
  throw new Error('Image missing for article block!');
};

export const processArticleBlock = (block: CsArticleBlock): ArticleBlockWithVideoJsonLd => {
  const csArticleBlockName = Object.entries(block).filter(
    ([, blockContent]) => !!blockContent
  )[0][0];
  switch (csArticleBlockName) {
    case 'article_text':
      return {
        type: 'text',
        text: block.article_text?.text ?? '',
      };
    case 'image_full_width':
      return {
        type: 'image-full',
        image: processMandatoryImage(block.image_full_width?.image),
      };
    case 'image_half_width':
      /* istanbul ignore if */
      if (!block.image_half_width?.wrapped_text) {
        throw new Error('image half width missing text');
      }
      return {
        type: 'image-half',
        image: processMandatoryImage(block.image_half_width?.image),
        alignment: block.image_half_width?.image_alignment ?? 'left',
        text: block.image_half_width?.wrapped_text ?? undefined,
      };
    case 'video':
      /* istanbul ignore if */
      if (!block.video?.video[0]?.url || !block.video.video[0].screen_reader_text) {
        throw new Error('video missing url or screen reader text');
      }
      return {
        type: 'video',
        ...processMandatoryVideo(block.video?.video || null),
      };
    /* istanbul ignore next */
    default:
      // In this case, we return an empty text block
      return {
        type: 'text',
        text: '',
      };
  }
};

const InPageNavArticleSections: React.FC<{
  sections: InPageNavArticleSectionProps[];
  navHeading: string;
  hero: HeroData;
  pageHasStickyHeader?: boolean;
}> = ({ sections, navHeading, hero, pageHasStickyHeader }) => {
  const navSections = sections
    .filter((section) => section.nav_section !== null)
    .map((section) => ({
      text: section.nav_section.inline_nav_text,
      id: section.nav_section.inline_nav_text,
    }));

  const articleSections = sections
    .filter((section) => section.article_section !== null)
    .map((section, index) => {
      const blocks = section.article_section.article_blocks.map(processArticleBlock);
      const sectionIndex = sections.indexOf(section);
      const previousSection = sections[sectionIndex - 1];
      const previousNavSectionText = previousSection?.nav_section?.inline_nav_text;

      const videoJsonLdElements = blocks
        .filter(
          (block): block is ArticleBlock & { jsonLd: string } =>
            !!block.jsonLd && block.type === 'video'
        )
        .map((block, subIndex) => (
          <Helmet
            // the order of these elements shouldn't change
            // eslint-disable-next-line react/no-array-index-key
            key={subIndex}
            script={[helmetJsonLdProp<VideoObject>(JSON.parse(block.jsonLd))]}
          />
        ));

      return (
        // Sections will never be reordered
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {videoJsonLdElements}
          <ArticleSection
            data-cy={`ArticleSection${index}`}
            id={previousNavSectionText ?? undefined}
            blocks={blocks}
          />
        </React.Fragment>
      );
    });

  return (
    <>
      <Grid alignLeft>
        {navSections.length > 0 && (
          <FullGridInPageNavMobile
            navHeading={navHeading}
            navSections={navSections}
            pageHasStickyHeader={pageHasStickyHeader}
            data-cy="Article mobile in-page nav"
          />
        )}
        <GridItem>
          <Hero hero={hero} />
        </GridItem>
        <GridItem desktop={1} tabletLandscape={1} />
        <GridItem desktop={8} tabletLandscape={8}>
          <article>{articleSections}</article>
        </GridItem>
        {navSections.length > 0 && (
          <GridItem desktop={3} tabletLandscape={3}>
            <InPageNavDesktop
              navHeading={navHeading}
              navSections={navSections}
              data-cy="Article desktop in-page nav"
            />
          </GridItem>
        )}
      </Grid>
    </>
  );
};

export default React.memo(InPageNavArticleSections);
