import { InPageNavMobile } from '@rsa-digital/evo-shared-components/components/InPageNav';
import { ColumnsByBreakpoint } from '@rsa-digital/evo-shared-components/helpers/columns/types';
import mediaQuery, {
  stylePerBreakpoint,
} from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { Theme } from '@rsa-digital/evo-shared-components/theme';
import styled, { css, ThemedStyledProps } from 'styled-components';

/*
  We need to add the full width grid item styling to the NavMobile because the polyfill
  library used to allow the nav to be sticky while allowing overflow only applies that
  stickiness within the directly containing html element. This means we can't place the 
  nav in a grid item but in the grid directly.
*/

export const FullGridInPageNavMobile = styled(InPageNavMobile)<{ pageHasStickyHeader?: boolean }>`
  flex: 0 0 auto;

  ${({ pageHasStickyHeader }) =>
    pageHasStickyHeader &&
    css`
      top: ${spacing(7)};

      ${mediaQuery.tabletPortrait`
        top: ${spacing(8)};
      `}
    `};

  ${stylePerBreakpoint(
    (breakpoint) => (props: ThemedStyledProps<Partial<ColumnsByBreakpoint>, Theme>) =>
      css`
        margin: 0 ${props.theme.layout[breakpoint].gutterPx / 2}px;
        width: calc(${100}% - ${props.theme.layout[breakpoint].gutterPx}px);
      `
  )}
`;
