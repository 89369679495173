import { graphql } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Article, NewsArticle } from 'schema-dts';
import { HeroData } from 'components/Hero';
import InPageNavArticleSections, {
  InPageNavArticleSectionProps,
} from 'components/InPageNavArticleSections';
import Layout from 'components/Layout';
import { CsBlock, mapBlocks } from 'helpers/blockMapper';
import { processPageMeta } from 'helpers/csTypeProcessors';
import { useAnalyticsPageVariables } from 'helpers/useAnalyticsPageVariables';
import { PageMeta } from 'types/contentStack';

type NewsArticlePageProps = {
  data: NewsArticlePageData;
};

type GuideTypes = Article | NewsArticle;

const NewsArticlePage: React.FC<NewsArticlePageProps> = ({ data }) => {
  const page = data.csNewsArticlePage;
  const { meta, breadcrumbs } = processPageMeta(page.page_meta, page.url);
  useAnalyticsPageVariables(
    {
      pageCategories: breadcrumbs.parent_links.map((link) => link.page_name),
      pageType: 'NewsArticlePage',
      articleName: page.hero.heading,
    },
    page.blocks,
    true
  );

  return (
    <>
      <Helmet script={[helmetJsonLdProp<GuideTypes>(JSON.parse(page.json_ld))]} />
      <Layout
        pageType="brochureware"
        meta={meta}
        breadcrumbs={breadcrumbs}
        complianceText={page.footer_compliance ?? undefined}
        showStickyHeader={page.show_sticky_header ?? undefined}
        showAlertNotification={page.show_alert_notification ?? undefined}>
        <InPageNavArticleSections
          sections={page.sections}
          navHeading={page.nav_section_heading}
          hero={page.hero}
          pageHasStickyHeader={page.show_sticky_header ?? undefined}
        />
        {page.blocks && mapBlocks(page.blocks, { pageMeta: page.page_meta, pageUrl: page.url })}
      </Layout>
    </>
  );
};
export default NewsArticlePage;

type NewsArticlePageData = {
  csNewsArticlePage: {
    title: string;
    url: string;
    hero: HeroData;
    page_meta: PageMeta;
    json_ld: string;
    footer_compliance: string | null;
    sections: InPageNavArticleSectionProps[];
    blocks: CsBlock[];
    nav_section_heading: string;
    show_sticky_header: boolean | null;
    show_alert_notification: boolean | null;
  };
};

export const query = graphql`
  query($id: String!) {
    csNewsArticlePage(id: { eq: $id }) {
      title
      url
      ...HeroNews
      footer_compliance
      nav_section_heading
      show_sticky_header
      show_alert_notification
      sections {
        ...InPageNavArticleSectionNews
      }
      blocks {
        ...DividerBlockNews
        ...RelatedContentBlockNews
        ...ShareWidgetBlockNews
        ...AuthorInfoBlockNews
        ...CookiesListBlockNews
      }
      page_meta {
        ...BreadcrumbsNews
        ...MetaDataTagsNews
      }
      json_ld
    }
  }
`;
